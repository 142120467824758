import React, { useEffect, useState } from 'react';

export default function MonthEndCountdown() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [serverTime, setServerTime] = useState(null);

  // Fetch server time (replace with your backend if needed)
  const fetchServerTime = async () => {
    try {
      const response = await fetch('https://worldtimeapi.org/api/ip'); // Public API to get server time
      const data = await response.json();
      setServerTime(new Date(data.datetime)); // Set the server time
    } catch (error) {
      console.error('Error fetching server time:', error);
      setServerTime(new Date()); // Fallback to client time
    }
  };

  // Calculate the time difference to the end of the current month
  const calculateTimeLeft = () => {
    const now = new Date();
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59); // End of the month at 23:59:59

    const diff = endOfMonth - now; // Difference in milliseconds
    if (diff <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    return { days, hours, minutes, seconds };
  };

  useEffect(() => {
    fetchServerTime(); // Fetch the server time on component load
  }, []);

  useEffect(() => {
    if (!serverTime) return; // Wait for server time to load
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft()); // Update countdown every second
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [serverTime]);

  return (
    <div
    style={{
      textAlign: 'center',
      fontSize: '20px',
      color: '#FFFFFF',
      borderRadius: '8px',
    }}
  >
    <div style={{ fontWeight: 'bold' }}>Ends In:</div>
    <div>
      {serverTime ? (
        <>
          {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
        </>
      ) : (
        <>Loading...</>
      )}
    </div>
  </div>
  
  );
}
