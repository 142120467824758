import React from 'react'
import { useEffect, useState } from 'react';
import { fund, userImg } from '../../assets/dashboard'
import { MdRefresh } from 'react-icons/md'
import { VscCopy } from 'react-icons/vsc'
import { Badge, Box, Flex, Image, Progress, Spinner, Text } from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { colors } from '../../styles/theme'
import { styled } from 'styled-components'
import copy from 'clipboard-copy';
import Cookies from 'js-cookie';
import { CurrencyFormatter } from '../../utils/common';
import { decrement, increment } from '../../store/reducers/loading';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import PhoneVerificationModal from './verify';
import MonthEndCountdown from './monthEndCountdown';

const UserDetails = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const toast = useToast()
  const isLoading = () => {
    return loadingCount > 0;
  }
  const navigate = useNavigate();
  const [acctName, setAcctName] = useState('')
  const [bankName, setBankName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [currency, setCurrency] = useState('')
  const [walletBalance, setWalletBalance] = useState('')
  const [profileImg, setProfileImg] = useState(null)
  const [showBalance, setShowBalance] = useState(false)
  const [linkCopied, setLinkCopied] = useState(false);
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [roles, setRoles] = useState([]);
  const [achievements, setAchievements] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const linkToCopy = `${accountNumber}`;


  const handleGetAchievement = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/achievements/get_rank`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }
      );

      if (response.status === 401) navigate('/auth/login');
      const data = await response.json();
      dispatch(decrement());

      if (response.status > 300) {
        toast({
          title: data.message || 'Error',
          status: 'error',
          isClosable: true,
        });
        return;
      }

      setAchievements(data.data);
    } catch (err) {
      dispatch(decrement());
      toast({
        title: 'Network Error',
        status: 'error',
        description: 'Check your internet connection',
        isClosable: true,
      });
    }
  };

  const handleGetData = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setAcctName(data.data.account?.account_name);
      setBankName(data.data.account?.bank_name);
      setAccountNumber(data.data.account?.account_number);
      setCurrency(data.data.account?.currency_code)
      setWalletBalance(data.data.wallet?.balance)
      setProfileImg(data.data.user?.profile_image)
      setFirstName(data.data.user?.first_name)
      setRoles(data?.data?.user?.roles.map(x => x.name))
      setPhoneNumber(data.data.user?.phone_number);
      setPhoneNumberVerified(data.data.user?.phone_number_verified)
   

    } catch (err) {
      dispatch(decrement());
      // Handle errors
      
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }
  useEffect(() => {
    handleGetAchievement();
    handleGetData();
  }, []);

  const handleCopyClick = async () => {
    try {
      await copy(linkToCopy);
      setLinkCopied(true);

      // Reset isCopied to false after 3 seconds
      setTimeout(() => {
        setLinkCopied(false);
      }, 3000);
    } catch (error) {
      console.error('Copy to clipboard failed: ', error);
    }
  };


  const AchievementProgress = (next_lowest, amount_spent, next_highest ) => {
    const min = next_lowest || 0; // Default to 0 if undefined
    const max = next_highest || amount_spent; // Default to 100 if undefined
    const value = amount_spent || 0;
  
    return (
      <Box>
        <Text fontSize="1rem" color="white" mb="0.5rem">
          Amount Spent: {CurrencyFormatter(value)}
        </Text>
        <Progress
          value={value}
          min={min}
          max={max}
          size="lg"
          colorScheme="green"
          borderRadius="8px"
          mb="0.5rem"
        />
        <Flex justifyContent="space-between" fontSize="0.9rem" color="gray.300">
          <Text>Next Lowest: {CurrencyFormatter(min)}</Text>
          <Text>Next Highest: {CurrencyFormatter(max)}</Text>
        </Flex>
      </Box>
    );
  };


  const renderAchievementRanks = () => {
    if (!achievements) return <Spinner />;
  
    const { rank, next_highest, next_lowest, amount_spent, first_position_reward, second_position_reward, third_position_reward } = achievements;
  
    const rankStyle = {
      1: { icon: "🥇", color: "gold.400", label: "1st Place" },
      2: { icon: "🥈", color: "gray.400", label: "2nd Place" },
      3: { icon: "🥉", color: "orange.400", label: "3rd Place" },
      unranked: { icon: "⬜", color: "gray.300", label: `${rank}th Place` },
    };
  
    const currentRank = rankStyle[rank] || rankStyle.unranked;
    
    return (
      <Box mt="0rem" p="1.5rem" border="0px solid" borderColor="gray.100" borderRadius="12px" boxShadow="lg">
        {/* Rank Section */}
        <Flex alignItems="center" justifyContent="center" mb="0rem">
          <Box textAlign="center">
            <Box fontSize="3rem" color={currentRank.color} mb="0.5rem">
              {currentRank.icon}
            </Box>
            <Text fontSize="1.2rem" fontWeight="bold" color="white">
              {currentRank.label}
            </Text>
          </Box>
        </Flex>
  
        {/* Rank Details */}
        {AchievementProgress(next_lowest, amount_spent, next_highest)}
      
        <MonthEndCountdown />
        {/* Rewards Section */}
        <Flex style={{marginTop: "30px"}} justifyContent="space-between" wrap="wrap" alignItems="center" gap="1rem">
          <Flex alignItems="center" gap="0.5rem">
            <Text fontSize="1.5rem">🥇</Text>
            <Badge colorScheme="yellow" backgroundColor="gold" fontSize="0.9rem" px="0.5rem">
              {CurrencyFormatter(first_position_reward)}
            </Badge>
          </Flex>
          <Flex alignItems="center" gap="0.5rem">
            <Text fontSize="1.5rem">🥈</Text>
            <Badge colorScheme="gray" backgroundColor="silver" fontSize="0.9rem" px="0.5rem">
              {CurrencyFormatter(second_position_reward)}
            </Badge>
          </Flex>
          <Flex alignItems="center" gap="0.5rem">
            <Text fontSize="1.5rem">🥉</Text>
            <Badge colorScheme="orange" backgroundColor="darkorange" fontSize="0.9rem" px="0.5rem">
              {CurrencyFormatter(third_position_reward)}
            </Badge>
          </Flex>
        </Flex>
      </Box>
    );
  };
  
  
  

  return (
    <div>
      <Flex flexDir={{ base: 'column-reverse', lg: 'row' }} gap='3rem' alignItems={'center'} justifyContent='center'>
        <Box mb={{ base: '1rem' }}>
          <Flex gap={'1rem'} alignItems='center'>
            <h3 className='amount'>{showBalance ? `${CurrencyFormatter(walletBalance)}` : '*******'}</h3>
            <div className='icon-wrapper' onClick={() => setShowBalance((showBalance) => !showBalance)}>
              {showBalance ? <ViewIcon /> : <ViewOffIcon />}
            </div>
            {isLoading() ?
              <div className='icon-wrapper'  >
                <Spinner />
              </div>
              :
              <div className='icon-wrapper' onClick={handleGetData} >
                <MdRefresh />
              </div>
            }
          </Flex>
          <p className='wallet'>Wallet Balance</p>
        </Box>

        <div className='divider' />

        <Flex justify={'center'} alignItems={'center'} gap='1rem'>
          <Image src={profileImg == null ? userImg : profileImg} alt='user-image' w={'45px'} h={'45px'} borderRadius='50%' />
          <Box>
            <h3 className='welcome'>Welcome, {firstName} 👋</h3>
            <p className='question'>What will you be doing today?</p>
            <p className='question'>Designation: {roles?.join(" , ")}</p>
          </Box>
          <a href='/dashboard/fund-wallet' className='fund-wallet'>
            <img src={fund} alt='fund-icon' />
            Fund Wallet
          </a>
        </Flex>
      </Flex>

      <Flex
        borderRadius='18px'
        background={`${colors.darkColor}`}
        backdropFilter='blur(15px)'
        w={{ base: '100%' }}
        h={{ base: '42px', lg: '36px' }}
        mt={{ base: '2rem' }}
        justify={'center'}
        align='center'
      >
        <Account>
          <p className='virtual'>{acctName}</p>
          <p className='number'>{linkToCopy}</p>
          <span className='bank' onClick={handleCopyClick}>{bankName} <VscCopy /></span>
          {linkCopied ? 'Copied!' : ''}
        </Account>
      </Flex>

      {renderAchievementRanks()}
          {phoneNumberVerified == 0 && phoneNumber.length > 5 &&
      <PhoneVerificationModal myNumber={phoneNumber} />}
    </div>
  )
}

export default UserDetails

const Account = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background: ${colors.lightColor};
  border-radius: 14px;
  padding: 15px 25px;

  @media(max-width: 992px) {
    width: 70%;
    flex-direction: column;
    gap: 5px;
  }

  .virtual {
      color: ${colors.darkColor};
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px; /* 200% */
      letter-spacing: 0.492px;

      @media(max-width: 992px) {
        line-height: 18px;
        text-align: center;
      }
  }
  .number {
      color: ${colors.darkColor};
      font-family: Raleway;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px; /* 145% */
      letter-spacing: 4.1px;

      @media(max-width: 992px) {
        font-size: 18px;
        line-height: 22px;
      }
  }
  .bank {
      color: ${colors.darkColor};
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 200% */
      letter-spacing: 0.492px;

      cursor: pointer;

      display: flex;
      align-items: center;
      gap: .6rem;

      svg {
        font-size: 18px;
      }

      @media(max-width: 992px) {
        line-height: 18px;
      }
  }
`;